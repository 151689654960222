import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Field } from "formik";

export const DivStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  border: none;
`;

export const InputDiv = styled(Box)`
  width: 100%;
  height: 3rem;
  border-radius: 20px;
  background-color: #f4f4f4;
  padding: 0 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const FieldStyle = styled(Field)`
  outline: 0;
`;

export const InputStyle = styled("input")`
  outline: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Geologica', sans-serif;
  font-family: 'Outfit', sans-serif;
`;

export const DateStyle = styled("input")`
  outline: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  border: 0;
  width:100%;
  font-family: 'Geologica', sans-serif;
  background: transparent;
  padding: 0 7px;
`;


export const SelectStyle = styled("select")`
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 3px 6px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Geologica', sans-serif;
  font-family: 'Outfit', sans-serif;
  line-height: 20px;
  color: #555555;
  outline: 0;
  vertical-align: middle;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
`;

export const TextAreaStyle = styled.textarea`
  /* Add your styling here */
  width: 100%;
  height: 100px;
  resize: vertical; /* Allow vertical resizing */
  /* Add any other styles you need */
`;



export const InputPlainStyle =
{
  border: 'none',
  borderBottom: 'none',
  outline: 'none',
  boxShadow: 'none',
  background: '#F5F5F5',
  padding: '6px',
  paddingLeft: '10px',
  borderRadius: '20px',
  fontSize: '13px',
  width: '100%',
  '&:not(.Mui-focused)': {
    borderColor: 'transparent',
  },
}


