

export const getRequestConfiguration = (extraHeader) => {
    const token = window.localStorage.getItem('token');
    // if (!token) return window.location.replace('/auth/login')
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`,
            ...extraHeader
        },

    }
    return header
}

export const putRequestUploadConfiguration = () => {
    const header = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }
    return header
}

export const generateTransactionReference = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomNum = Math.floor(Math.random() * 10000); // Generate a random number

    // Combine timestamp and random number to create a unique string
    const uniqueString = `${timestamp}-${randomNum}`;

    return uniqueString;
}



export const getCroppedImg = async (image, crop, fileName = 'cropped.jpg') => {
    const imageSrc = new Image();
    imageSrc.src = image;

    const canvas = document.createElement('canvas');
    const scaleX = imageSrc.naturalWidth / imageSrc.width;
    const scaleY = imageSrc.naturalHeight / imageSrc.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
        imageSrc,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                // Create a File object from the Blob
                const file = new File([blob], fileName, { type: blob.type });
                resolve(file);
            },
            'image/jpeg'
        );
    });
};



export function convertDateString(inputDateString) {
    // Create a Date object from the input string
    const dateObject = new Date(inputDateString);

    // Extract year, month, and day from the Date object
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(dateObject.getDate()).padStart(2, '0');

    // Create the desired output string
    const outputDateString = `${year}-${month}-${day}`;

    return outputDateString;
}


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function addSpaceAfterComma(inputString) {
    if (Array.isArray(inputString)) {
        var result = inputString?.join(', ');
        return result;
    }
    return ''
}

export function getDivisions(totalNumber, divider) {
    if (typeof totalNumber !== 'number' || typeof divider !== 'number') {
        throw new Error('Both parameters must be numbers');
    }

    if (divider === 0) {
        throw new Error('Divider cannot be zero');
    }

    const divisions = [];
    for (let i = divider; i <= totalNumber; i += divider) {
        divisions.push(i);
    }

    return divisions;
}

export function formatNumberWithCommas(number) {
    if (typeof number !== 'number') {
        throw new Error('Input must be a number');
    }

    return number.toLocaleString();
}
