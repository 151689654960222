import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack5";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field } from "formik";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { apiUserLogin } from "../../../_apis_/authentication";
import './LoginForm.css'

// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";


//
import { MIconButton } from "../../@material-extend";
import { DivStyle, InputDiv, FieldStyle } from "src/components/UIElements/Input";

// ----------------------------------------------------------------------




export default function LoginForm() {
  const { login } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const result = await apiUserLogin(values);
      if (result.status == "error") {
        resetForm();
        setSubmitting(false);
        setErrors({ afterSubmit: result.msg });
      } else if (result?.data.status == "vetting") {
        resetForm();
        setSubmitting(false);
        navigate(`/processing-account/${result?.data?._id}`)
      } else {
        enqueueSnackbar("Login success", {
          variant: "success",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
        await login(result.data.data);
      }
    },
  });

  const { errors, isSubmitting, handleSubmit } =
    formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
          <DivStyle>
            <label htmlFor="email" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'bold' }}>Email
              <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
            </label>
            <InputDiv>
              <FieldStyle
                id="email"
                type="email"
                name="email"
                className="input_style"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </InputDiv>
            {errors && <Typography sx={{ color: 'red', fontWeight: 'bold', marginLeft: '10px', }} variant="body2" id="feedback">{errors.email}</Typography>}
          </DivStyle>

          <DivStyle>
            <label htmlFor="email" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'bold' }}>Password
              <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
            </label>
            <InputDiv>
              <Icon icon="mdi:lock-open" color="#d66233" width="30" />
              <FieldStyle
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                className="input_form"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {showPassword && <Icon style={{ cursor: 'pointer' }} icon="bi:eye-fill" color="#d66233" width="30" onClick={handleShowPassword} />}
              {!showPassword && <Icon style={{ cursor: 'pointer' }} icon="ri:eye-close-fill" color="#d66233" width="30" onClick={handleShowPassword} />}
            </InputDiv>
            {errors && <Typography sx={{ color: 'red', fontWeight: 'bold', marginLeft: '10px', }} variant="body2" id="feedback">{errors.password}</Typography>}

          </DivStyle>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction={{ xs: "column", sm: "row" }}
          sx={{ my: "0.5rem" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 0 }}
          >
            <Checkbox
              {...label}
              sx={{
                color: "#d66233",
                "&.Mui-checked": {
                  color: "#d66233",
                },
              }}
            />
            <Typography

              gutterBottom
              sx={{ marginBottom: 0, fontSize: "12px" }}
            >
              Keep me Logged in
            </Typography>
          </Stack>
          <Link
            component={RouterLink}
            to={PATH_AUTH.resetPassword}
            sx={{ color: "#D66233", fontSize: "12px", }}
          >
            Forgot password?
          </Link>
        </Stack>
        <Box display="flex" justifyContent="center">
          <LoadingButton
            sx={{ background: "#D66233", color: "#fff", minWidth: "170px", fontSize: '14px', borderRadius: '10px' }}
            size="large"
            type="submit"
            loading={isSubmitting}
          >
            Sign in
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
