// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'client',
    items: [
      {
        title: 'View dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Single Gig', path: PATH_DASHBOARD.general.outsourcing, icon: ICONS.ecommerce },
      { title: 'Managed Services', path: PATH_DASHBOARD.general.team, icon: ICONS.analytics },
      { title: 'Marketplace', path: PATH_PAGE.talents, icon: ICONS.booking },

    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Escrow', path: PATH_DASHBOARD.escrow.root,
        icon: ICONS.banking,

      },
      {
        title: 'User',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Account', path: PATH_DASHBOARD.user.account },
        ]
      },
      {
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: ICONS.chat,
        children: [
          { title: 'Ticket', path: PATH_DASHBOARD.support.support },
          {
            title: 'Whatsapp',
            path: 'https://api.whatsapp.com/send?phone=2348120563875',
            target: '_blank',
          }
        ]
      },

    ]
  }
];

export default sidebarConfig;
