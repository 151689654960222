// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking')
};

const consultantSidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'Consultant',
        items: [
            {
                title: 'View dashboard',
                path: PATH_DASHBOARD.general.app,
                icon: ICONS.dashboard
            },
            { title: 'Jobs', path: PATH_DASHBOARD.general.outsourcing, icon: ICONS.ecommerce },
        ]
    },

    // ----------------------------------------------------------------------
    {
        subheader: 'management',
        items: [
            // MANAGEMENT : USER
            {
                title: 'Escrow', path: PATH_DASHBOARD.escrow.root
            },
            {
                title: 'User',
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                children: [
                    { title: 'Account', path: PATH_DASHBOARD.user.account },
                    { title: 'Profile', path: PATH_DASHBOARD.user.profile }
                ]
            },
            {
                title: 'Support',
                path: PATH_DASHBOARD.support.root,
                icon: ICONS.chat,
                children: [
                    { title: 'Ticket', path: PATH_DASHBOARD.support.support },
                    {
                        title: 'Whatsapp',
                        path: 'https://api.whatsapp.com/send?phone=2348120563875',
                        target: '_blank',
                    }
                ]
            }
        ]
    }
];

export default consultantSidebarConfig;
