import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';



// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth()
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'signup-affiliate', element: <AffiliateSignUp /> },
        { path: 'signup-organisation', element: <OrganisationSignUp /> },
        { path: 'signup-individual', element: <IndividualSignUp /> },
        { path: 'signup-consultant', element: <ConsultantSignUp /> },
      ]
    },

    // Dashboard Routes
    (user?.account_type == 'consultant') ?
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: '', element: <Navigate to="/dashboard/app" replace /> },
          { path: '', element: <GeneralAppConsultant /> },
          { path: 'app', element: <GeneralAppConsultant /> },
          { path: 'outsourcing', element: <GeneralOutsourcingConsultant /> },
          { path: 'team', element: <GeneralTeam /> },
          {
            path: 'user',
            children: [
              { path: '', element: <Navigate to="/dashboard/user/account" replace /> },
              { path: 'account', element: <UserAccount /> },
              { path: 'profile', element: <ViewProfile /> },
              { path: 'profile-edit', element: <UpdateProfile /> }
            ]
          },
          {
            path: 'support',
            children: [
              { path: '', element: <Navigate to="/dashboard/support/support" replace /> },
              { path: 'support', element: <Support /> },
              { path: 'support/:id', element: <SupportInfo /> }
            ]
          },
          {
            path: 'escrow',
            children: [
              { path: '', element: <Escrow /> },
              { path: 'payments/:id', element: <PaymentDetails /> },
            ]
          },

          {
            path: 'outsourcing',
            children: [
              { path: 'new', element: <NotFound /> },
              { path: 'details', element: <OutsourceDetailsConsultant /> },
              { path: 'messaging', element: <OutsourcingConsultantMessaging /> }
            ]
          },
          {
            path: 'team',
            children: [
              { path: 'new', element: <TeamCreate /> },
              { path: 'details', element: <HireTeamDetails /> }
            ]
          },

        ]
      }
      :
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { path: '', element: <Navigate to="/dashboard/app" replace /> },
          { path: '', element: <GeneralAppClient /> },
          { path: 'app', element: <GeneralAppClient /> },
          { path: 'outsourcing', element: <GeneralOutsourcing /> },
          { path: 'hire-consultant', element: <GeneralConsultant /> },
          { path: 'team', element: <GeneralTeam /> },
          {
            path: 'user',
            children: [
              { path: '', element: <Navigate to="/dashboard/user/account" replace /> },
              { path: 'account', element: <UserAccount /> }
            ]
          },
          {
            path: 'support',
            children: [
              { path: '', element: <Navigate to="/dashboard/support/support" replace /> },
              { path: 'support', element: <Support /> },
              { path: 'support/:id', element: <SupportInfo /> }
            ]
          },
          {
            path: 'escrow',
            children: [
              { path: '', element: <Escrow /> },
              { path: 'payments/:id', element: <PaymentDetails /> },
            ]
          },
          {
            path: 'outsourcing',
            children: [
              { path: 'new', element: <OutsourceCreate /> },
              { path: 'details/:id', element: <OutsourceDetails /> },
              { path: 'agreement', element: <OutsourcingAgreement /> },
              { path: 'payment', element: <OutsourcingPayment /> },
              { path: 'messaging', element: <OutsourcingClientMessaging /> },
              { path: 'manage', element: <OutsourcingManage /> },
            ]
          },
          {
            path: 'hire-consultant',
            children: [
              { path: 'new', element: <ConsultantCreate /> },
              { path: 'details', element: <HireConsultantDetails /> }
            ]
          },
          {
            path: 'team',
            children: [
              { path: 'new', element: <TeamCreate /> },
              { path: 'details/:id', element: <HireTeamDetails /> },
              { path: 'agreement', element: <TeamAgreement /> },
              { path: 'payment', element: <TeamPayment /> },
              { path: 'manage', element: <TeamManage /> },
            ]
          },
          // {
          //   path: 'consultants',
          //   children: [
          //     { path: 'consultant_info/:title', element: <Consultant /> },
          //   ]
          // },
        ]
      },

    // Client
    {
      path: '',
      children: [
        { path: '/', element: <Client /> },
        { path: 'team', element: <ClientInput /> },
        { path: 'talent/:tid', element: <SingleTalent /> },
        { path: 'talents', element: <TalentList /> },
      ]
    },
    // Consultant
    {
      path: 'consultant',
      children: [
        { path: 'single', element: <SingleConsultant /> },
        { path: 'individual', element: <IndividualConsult /> },
        {
          path: 'individual',
          children: [
            { path: '', element: <IndividualConsult /> },
            { path: 'view', element: <ViewConsult /> },
            { path: 'engage', element: <EngageConsult /> },
            { path: 'agree', element: <AgreeConsult /> },
            { path: 'payment', element: <Payment /> },
          ]
        },
        {
          path: 'service',
          children: [
            { path: '', element: <ManagedService /> },
            { path: 'view', element: <ManagedView /> },
            { path: 'engage', element: <EngageService /> },
            { path: 'agree', element: <ServiceAgreement /> },
            { path: 'payment', element: <ServicePayment /> },
          ]
        },
      ]
    },
    //individual
    {
      path: 'individual',
      children: [
        { path: '', element: <IConsult /> },
        { path: 'view', element: <ViewIConsult /> },
      ]
    },
    // // Main Routes
    {
      path: '*',
      children: [
        { path: 'processing-account/:id', element: <ComingSoon /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ]);
}


// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
const VerifyEmail = Loadable(lazy(() => import('../pages/authentication/VerifyEmail')));
const AffiliateSignUp = Loadable(lazy(() => import('../pages/signup/AffiliateSignUp')));
const OrganisationSignUp = Loadable(lazy(() => import('../pages/signup/OrganisationSignUp')));
const IndividualSignUp = Loadable(lazy(() => import('../pages/signup/IndividualSignUp')));
const ConsultantSignUp = Loadable(lazy(() => import('../pages/signup/ConsultantSignUp')));

// Dashboard
const GeneralAppClient = Loadable(lazy(() => import('../pages/dashboard/client/GeneralAppClient')));
const GeneralAppConsultant = Loadable(lazy(() => import('../pages/dashboard/GeneralAppConsultant')));
const GeneralOutsourcing = Loadable(lazy(() => import('../pages/dashboard/client/GeneralOutsourcing')));
const GeneralOutsourcingConsultant = Loadable(lazy(() => import('../pages/dashboard/consultant/GeneralOutsourcingConsultant')));
const GeneralConsultant = Loadable(lazy(() => import('../pages/dashboard/GeneralConsultant')));
const GeneralTeam = Loadable(lazy(() => import('../pages/dashboard/client/GeneralTeam')));
const OutsourceDetails = Loadable(lazy(() => import('../pages/dashboard/client/OutsourceDetails')));
const OutsourceDetailsConsultant = Loadable(lazy(() => import('../pages/dashboard/consultant/OutsourceDetailsConsultant')));

const OutsourcingAgreement = Loadable(lazy(() => import('../pages/dashboard/client/OutsourcingAgreement')));
const OutsourcingPayment = Loadable(lazy(() => import('../pages/dashboard/client/OutsourcingPayment')));
const OutsourcingConsultantMessaging = Loadable(lazy(() => import('src/components/_dashboard/general-outsourcing/consultant/OutsourcingConsultantMessaging')));
const OutsourcingClientMessaging = Loadable(lazy(() => import('src/components/_dashboard/general-outsourcing/client/OutsourcingClientMessaging')));
const OutsourcingManage = Loadable(lazy(() => import('../pages/dashboard/client/OutsourcingManage')));

const TeamAgreement = Loadable(lazy(() => import('../pages/dashboard/client/TeamAgreement')));
const TeamPayment = Loadable(lazy(() => import('../pages/dashboard/client/TeamPayment')));
const TeamManage = Loadable(lazy(() => import('../pages/dashboard/client/TeamManage')));

// const Consultant = Loadable(lazy(() => import('../pages/dashboard/Consultant')));
const HireConsultantDetails = Loadable(lazy(() => import('../pages/dashboard/HireConsultantDetails')));

//consultant
const SingleConsultant = Loadable(lazy(() => import('../pages/Consultant/SingleConsultant')));
const IndividualConsult = Loadable(lazy(() => import('../pages/Consultant/individual/IndividualConsult')));
const ViewConsult = Loadable(lazy(() => import('../pages/Consultant/individual/ViewConsultant')));
const EngageConsult = Loadable(lazy(() => import('../pages/Consultant/individual/EngageConsultant')));
const AgreeConsult = Loadable(lazy(() => import('../pages/Consultant/individual/AgreeConsultant')));
const Payment = Loadable(lazy(() => import('../pages/Consultant/individual/Payment')));

//service
const ManagedService = Loadable(lazy(() => import('../pages/Consultant/managed/ManagedService')));
const ManagedView = Loadable(lazy(() => import('../pages/Consultant/managed/ManagedView')));
const EngageService = Loadable(lazy(() => import('../pages/Consultant/managed/EngageService')));
const ServiceAgreement = Loadable(lazy(() => import('../pages/Consultant/managed/ServiceAgreement')));
const ServicePayment = Loadable(lazy(() => import('../pages/Consultant/managed/ServicePayment')));

const IConsult = Loadable(lazy(() => import('../pages/Consultant/IConsultant/IConsult')));
const ViewIConsult = Loadable(lazy(() => import('../pages/Consultant/IConsultant/ViewIConsult')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Escrow = Loadable(lazy(() => import('../pages/dashboard/Escrow')));
const PaymentDetails = Loadable(lazy(() => import('../pages/dashboard/PaymentDetails')));
const OutsourceCreate = Loadable(lazy(() => import('../pages/dashboard/OutsourceCreate')));
const ConsultantCreate = Loadable(lazy(() => import('../pages/dashboard/ConsultantCreate')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/TeamCreate')));
const HireTeamDetails = Loadable(lazy(() => import('../pages/dashboard/HireTeamDetails')));

// Client
const Client = Loadable(lazy(() => import('../pages/Client')));
const ClientInput = Loadable(lazy(() => import('../pages/Client/ClientInput')));
const TalentList = Loadable(lazy(() => import('../pages/Client/TalentList')));
const UpdateProfile = Loadable(lazy(() => import('../pages/Client/UpdateProfile')));
const SingleTalent = Loadable(lazy(() => import('../pages/Client/SingleTalent')));
const ViewProfile = Loadable(lazy(() => import('../pages/Client/ViewProfile')));
const Support = Loadable(lazy(() => import('../pages/dashboard/Support')));
const SupportInfo = Loadable(lazy(() => import('../pages/dashboard/SupportInfo')));


// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Sign up
