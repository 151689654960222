// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
// routes
// hooks
import useAuth from "../../hooks/useAuth"; // components
import Page from "../../components/Page";
import { LoginForm } from "../../components/authentication/login";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const BoxImg = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20vh',
  [theme.breakpoints.down("sm")]: {
    height: '10vh'
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 450,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    px: 0,
    width: "100%",
    maxWidth: 450,
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const navigate = useNavigate();
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | 1Capacity">
      <Container
        sx={{
          backgroundImage: "url(" + "/static/illustrations/home.png" + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%!important",
        }}
      >
        <BoxImg sx={{ cursor: "pointer" }} onClick={() => navigate('/')}
        >
          <img
            src={process.env.PUBLIC_URL + "/static/iconText.png"}
            alt="icon"
            width="120px"
          />
        </BoxImg>

        <ContentStyle
          sx={{
            background: "rgb(255, 255, 255)",
            backdropFilter: "blur(7.5px)",
            px: "3rem",
            py: "2rem",
            minHeight: "370px",
            borderRadius: "40px",
            width: "500px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ mb: 3, }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center", color: '#2c393f', }}
              >
                Sign In
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center", color: 'text.secondary', }}>
                Hey, 👋🏼 Enter your details below to login to your account
              </Typography>
            </Box>
          </Stack>
          {method !== "auth0" ? (
            <LoginForm />
          ) : (
            <Button
              sx={{ background: "#DB5118", textAlign: "center" }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleLoginAuth0}
            >
              Login
            </Button>
          )}
          <Typography variant="body2" sx={{ textAlign: "center", m: 3 }}>
            Don't have an account?
            <span style={{ fontWeight: "bold", cursor: "pointer" }} ><a style={{ textDecoration: "none" }} href={`${process.env.REACT_APP_SIGNUP}auth/signup-consultant`}> Signup</a></span>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle >
  );
}
