import axios from "axios";
let uri;

if (process.env.REACT_APP_ENVIRONMENT == 'development') {
    uri = 'http://localhost:5004/' // local
} else if (process.env.REACT_APP_ENVIRONMENT == 'staging') {
    uri = 'https://dpd-staging-ad4164fec942.herokuapp.com/' // staging
} else if (process.env.REACT_APP_ENVIRONMENT == 'production') {
    uri = 'https://dpd-online-server.herokuapp.com/' // production
}

const Axios = axios.create({ baseURL: uri + 'v1' });

Axios.interceptors.response.use(
    (response) => {
        if (response.status == "expired") {
            navigate('/auth/login');
        } return response;
    },
    (error) => {
        console.error('Axios error:', error);
        return Promise.reject(error);
    }
);

export const BaseURL = uri;
export default Axios;

