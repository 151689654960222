import { useState, createContext } from "react";

export const DataContext = createContext()

export default function DataContextProvider(props) {
    const [task, setTask] = useState()
    const [engagedConsultant, setEngagedConsultant] = useState(null)
    const [socketConsultant, setSocketConsultant] = useState()
    const [socketClient, setSocketClient] = useState()

    return (
        <DataContext.Provider value={{ task, setTask, engagedConsultant, setEngagedConsultant, socketConsultant, setSocketConsultant, socketClient, setSocketClient }}>
            {props.children}
        </DataContext.Provider>
    );
}