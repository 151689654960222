import io from 'socket.io-client';
import { BaseURL } from 'src/assets/utils/http-request'
const token = window.localStorage.getItem('token');

const socket = io(BaseURL, {
    autoConnect: false,
    reconnectionDelayMax: 15000,
    query: {
        token: token,
    },
});

export default socket;