import request from '../assets/utils/http-request';
import { getRequestConfiguration } from '../assets/utils/helper';

export const apiUserLogin = async (values) => {
    let uri = '';
    try {
        const uri = `/user/auth/login`;
        return (await request.post(uri, values, {}))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


export const apiUserForgotPassword = async (values) => {
    let uri = '';

    try {
        const uri = `/user/auth/forgot-password?email=${values}`;
        let result = (await request.get(uri, {})).data
        return result
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


export const apiResetPassword = async (values) => {
    let uri = '';
    try {
        const uri = `/user/auth/reset-password`;
        return (await request.post(uri, values, {}))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


export const apiGetUserDetails = async (token) => {
    let uri = '';
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    try {
        const uri = `/user/user/get-user`;
        return (await request.get(uri, header))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}



export const apiGetUser = async (value) => {
    const cfg = await getRequestConfiguration();
    let uri = '';
    try {
        const uri = `/user/user/get-user`;
        let result = (await request.get(uri, cfg)).data
        return result
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}