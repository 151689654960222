import { Box } from '@material-ui/core';
import logo from '../assets/images/logo3.png';
import blockLogo from '../assets/images/favicon.png';

export default function Logo({ sx, img_style }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img style={{ ...img_style }} className="logo-image" src={logo} />
    </Box>
  );
}

export function BlockLogo({ sx, img_style }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img style={{ ...img_style }} className="logo-image" src={blockLogo} />
    </Box>
  );
}

 