// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changepassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  talents: '/talents',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    outsourcing: path(ROOTS_DASHBOARD, '/outsourcing'),
    hireConsultant: path(ROOTS_DASHBOARD, '/hire-consultant'),
    team: path(ROOTS_DASHBOARD, '/team'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    support: path(ROOTS_DASHBOARD, '/user/support'),
    supportInfo: path(ROOTS_DASHBOARD, `/user/support/:id`)
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support/support'),
    support: path(ROOTS_DASHBOARD, '/support/support'),
    supportInfo: path(ROOTS_DASHBOARD, `/support/support/:id`)
  },
  escrow: {
    root: path(ROOTS_DASHBOARD, '/escrow')
  },
  outsourcing: {
    root: path(ROOTS_DASHBOARD, '/outsourcing'),
    newOutsource: path(ROOTS_DASHBOARD, '/outsourcing/new'),
    outsourcingDetails: path(ROOTS_DASHBOARD, '/outsourcing/details'),
    outsourcingAgreement: path(ROOTS_DASHBOARD, '/outsourcing/agreement'),
    outsourcingPayment: path(ROOTS_DASHBOARD, '/outsourcing/payment'),
    outsourcingMessaging: path(ROOTS_DASHBOARD, '/outsourcing/messaging'),
  },
  hireConsultant: {
    root: path(ROOTS_DASHBOARD, '/hire-consultant'),
    newHireConsultant: path(ROOTS_DASHBOARD, '/hire-consultant/new'),
    hireConsultantDetails: path(ROOTS_DASHBOARD, '/hire-consultant/details'),
  },
  consultants: {
    root: path(ROOTS_DASHBOARD, '/consultants'),
    consultant_lists: path(ROOTS_DASHBOARD, '/consultants/consultant_lists'),
    consultant_info: path(ROOTS_DASHBOARD, '/consultants/consultant_info/:title'),
  },
  team: {
    root: path(ROOTS_DASHBOARD, '/team'),
    newTeam: path(ROOTS_DASHBOARD, '/team/new'),
    hireTeamDetails: path(ROOTS_DASHBOARD, '/team/details/:id'),
    teamAgreement: path(ROOTS_DASHBOARD, '/team/agreement'),
    teamPayment: path(ROOTS_DASHBOARD, '/team/payment'),
  },
  talents: {
    root: path(ROOTS_DASHBOARD, '/talents'),
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
